import { makeAutoObservable, runInAction } from "mobx";
import { Store, ITransactionHistoryStore, AllTransactionHistoryData, AllConcertsForTransactionHistoryData } from "../global/types";
import { getAllConcertsForTransactionHistoryData, getAllTransactionHistoryData } from "../api/transactionHistoryAction";

class TransactionHistoryStore implements ITransactionHistoryStore {
    rootStore: Store;
    allTransactionHistoryData: AllTransactionHistoryData[] = [];
    allConcertsForTransactionHistoryData : AllConcertsForTransactionHistoryData[] = [];
    error: string | null = null;
    loading: boolean = false;
    page: number = 1;
    searchTerm: string = '';
    limit: number = 10;
    transactionStatus: string = '';
    concertTitle: string = '';
    startDate: string | Date = '';
    endDate: string | Date = '' ;
    totalItems: number = 0;
    totalPages: number = 0;
    customerId: string = '';

    constructor(rootStore: Store) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    setLoading = (loading: boolean) => {
        this.loading = loading;
    }

    fetchAllTransactionHistoryData = async (
        action: 'change_page' | 'search' | 'change_limit' | 'change_transaction_status' | 'change_concert_title' | 'change_date_range' |'',
        page: number,
        searchTerm: string,
        limit: number,
        transactionStatus: string,
        concertTitle: string,
        startDate: string | Date,
        endDate: string | Date,
        customerId?: string
    ) => {
        this.setLoading(true);
        try {
            runInAction(() => {
                switch (action) {
                    case 'change_page':
                        this.page = page;
                        break;
                    case 'search':
                        this.searchTerm = searchTerm;
                        this.page = 1;
                        break;
                    case 'change_limit':
                        this.limit = limit
                        this.page = 1
                        break;
                    case 'change_transaction_status':
                        this.transactionStatus = transactionStatus
                        this.page = 1
                        break;
                    case 'change_concert_title':
                        this.concertTitle = concertTitle
                        this.page = 1
                        break;
                    case 'change_date_range':
                        this.startDate = startDate
                        this.endDate = endDate
                        this.page = 1
                        break;
                    default:
                        break;
                }
                this.customerId = customerId || ''
            })
            const data = {
                page: this.page,
                limit: this.limit,
                search: this.searchTerm?.trim(),
                transaction_status: this.transactionStatus.toUpperCase(),
                concert_id: this.concertTitle,
                start_date: this.startDate,
                end_date: this.endDate,
                customer_id: this.customerId
            }
            const response = await getAllTransactionHistoryData(data);
            runInAction(() => {
                this.allTransactionHistoryData = response.data?.data?.transactions || []
                this.totalItems = response.data?.data?.totalItems;
                this.totalPages = response.data?.data?.totalPages;
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    fetchAllConcertsForTransactionHistoryData = async () => {
        this.setLoading(true);
        try {
            const response = await getAllConcertsForTransactionHistoryData();
            runInAction(() => {
                this.allConcertsForTransactionHistoryData = [
                    { _id: 'All', title: 'All' }, // Add 'All' option
                    ...(response.data?.data?.length > 0 
                        ? response.data.data.map((concert: { _id: string; title: string }) => ({
                            _id: concert._id,
                            title: concert.title,
                        })) 
                        : []
                    ),
                ]
            })
        } catch (error: unknown) {
            this.rootStore.authStore.handleUnauthorized(error);
        } finally {
            this.setLoading(false);
        }
    }

    resetTransactionHistoryPageDataStates = () => {
        runInAction(() => {
            this.allTransactionHistoryData = [];
            this.allConcertsForTransactionHistoryData = [];
            this.error = null;
            this.loading = false;
            this.page = 1;
            this.searchTerm = '';
            this.limit = 10;
            this.transactionStatus = '';
            this.concertTitle = '';
            this.startDate = '';
            this.endDate = '' ;
            this.totalItems = 0;
            this.totalPages = 0;
        })
    }
}

export default TransactionHistoryStore;
