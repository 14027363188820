import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../global/types";

export const logIn = async (email: string, password: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/login`;
    const body = { email, password };
    return axios.post(url, body);
};

export const logOut = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/logout`;
    return axios.post(url);
};

export const changePassword = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/change-password`;
    return axios.post(url, data);
}

export const getAllBootlegData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/bootleg?page=${data.page}&limit=${data.limit}&search=${data.search}`;
    return axios.get(url);
}

export const getOneUserData = async (id: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/user/details/${id}`;
    return axios.get(url);
}

export const updateBootlegData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/bootleg/edit`;
    return axios.post(url, data);
}

export const createBootleg = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/bootleg/create`;
    return axios.post(url, data);
}

export const requestOtp = async (email: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/forgot-password`;
    return axios.post(url, { email });
}

export const verifyOtp = async (data: {email: string, otp: string}): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/verify-otp`;
    return axios.post(url, data);
}

export const resetPassword = async (data: {email: string, new_password: string}): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/reset-password`;
    return axios.post(url, data);
}

export const getAllCustomersData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/users/user?page=${data.page}&limit=${data.limit}&search=${data.search}&login_type=${data.login_type}`;
    return axios.get(url);
}

export const getUserProfile = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/profile`;
    return axios.get(url);
}

export const editUserProfile = async (name: string): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/profile/edit`;
    return axios.post(url, { name });
}

export const getAllPricesForConcertData = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/transaction/concert-list`;
    return axios.get(url);
}
