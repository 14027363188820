import axios, { AxiosResponse } from "axios";
import { Dictionary } from "../global/types";

export const getAllTransactionHistoryData = async (data: Dictionary<string>): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/transaction/history-list?page=${data.page}&limit=${data.limit}&search=${data.search}&transaction_status=${data.transaction_status}&concert_id=${data.concert_id}&start_date=${data.start_date}&end_date=${data.end_date}&customer_id=${data.customer_id}`;
    return axios.get(url);
}

export const getAllConcertsForTransactionHistoryData = async (): Promise<AxiosResponse> => {
    const url = `${process.env.REACT_APP_BASE_URL}/api/admin/transaction/concert-list`;
    return axios.get(url);
}